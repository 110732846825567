<template>
    <div class="article">
        <BannerInnerPage />

        <div class="container article__breads" v-if="!preloader">
            <Breads :links="breadLinks" />
        </div>

        <div class="article__inner" v-if="!preloader">
            <div class="container" v-if="Object.keys(article).length">
                <SectionTitle :title="article.title" :hasButton="false"/>

                <div class="article-content">
                    <div class="article-content__header">
                        <div class="article-content__headerItem">
                            <span class="material-icons-round article-content__headerIcon">access_alarm</span>
                            <p class="article-content__headerP" style="text-transform: capitalize;">{{ article.date | getDate }}</p>
                        </div>
                        <div class="article-content__headerItem" v-if="article.author">
                            <span class="material-icons-round article-content__headerIcon">person_outline</span>
                            <p class="article-content__headerP">{{ article.author }}</p>
                        </div>

                        <div class="article-content__others" v-if="Object.keys(article.others).length">
                            <div class="article-content__othersItem"
                                v-for="(i, index) in othersHeaderValues"
                                :key="index"
                            >
                                <span v-html="i"></span>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="articleContent.length"
                        class="article-content__body"
                        v-for="(a, index) in articleContent"
                        :key="`editor` + index"
                        v-html="a.value"
                        :data-order="a.order"
                        :style="`order: ${a.order}`"
                    >
                    </div>

                    <div class="article-content__sliderWrapper" v-for="(slider, index) in articleSliders" :key="index" :data-order="slider.order" :style="`order: ${slider.order}`">
                        <h4 class="typography typography--h4 article-content__sliderTitle" v-if="slider.name && slider.value.length">{{ slider.name }}</h4>

                        <VueSlickCarousel
                            v-if="slider.value.length"
                            v-bind="sliderSettings"
                            class="article-content__slider"
                        >
                            <div class="article-content__sliderItem" v-for="(slide, index) in slider.value" :key="index">
                                <img class="article-content__sliderItemImage" v-if="slide.image" :src="storage(slide.image)" alt="image">
                                <p class="article-content__sliderItemTitle">{{ slide.title }}</p>
                                <p class="article-content__sliderItemDesc">{{ slide.description }}</p>
                            </div>

                             <template #prevArrow>
                                <span class="material-icons">keyboard_arrow_up</span>
                            </template>

                            <template #nextArrow>
                                <span class="material-icons">keyboard_arrow_down</span>
                            </template>

                        </VueSlickCarousel>

                    </div>


                    <div class="article-content__fileItemWrapper"
                        :data-order="af.order"
                        v-if="articleFiles"
                        v-for="(af, index) in articleFiles"
                        :style="`order: ${af.order}`"
                        :key="`file` + index"
                    >
                        <h4 class="typography typography--h4 article-content__fileTitle">{{ $t('pages.Article.fileContent') }}:</h4>
                        <a
                            class="article-content__fileItem"
                            :href="storage(f.file)"
                            target="_blank"
                            v-for="f in af.value"
                            :key="f.id"
                        >
                            <img class="article-content__fileImage" :src="fileImage(f.type, f.property)" alt="jpg">
                            <p class="typography typography--paragraph" style="font-size: 18px">{{ f.name }}</p>
                        </a>
                    </div>

                    <div class="article-content__map"
                        :data-order="m.order"
                        v-if="articleMap"
                        :style="`order: ${m.order}`"
                        v-for="(m, index) in articleMap"
                        :key="`map` + index"
                    >

                        <l-map
                            class="article-content__mapContent"
                            :id="`map-${m.order}`"
                            style="height: 750px; width: 100%"
                            :zoom="zoom"
                            :center="m.value.geolocation.split(',')"
                        >
                            <l-tile-layer :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"></l-tile-layer>

                            <l-marker :lat-lng="m.value.geolocation.split(',')" ref="marker">
                                <l-popup>
                                    <localized-link :to="{ name: 'Tour', params: { uri: m.value.uri } }">{{ m.value.name }}</localized-link>
                                    <div class="article-content__mapContentDesc" v-html="m.value.description"></div>
                                </l-popup>
                            </l-marker>

                        </l-map>

                        <localized-link class="article-content__mapLink" :to="{ name: 'Tour', params: { uri: m.value.uri } }">
                            {{ $t('pages.Article.tourLink') }}
                        </localized-link>
                    </div>

                    <div class="article-content__navigation" :data-order="99" :style="`order: 99`" v-if="Object.keys(navigation).length">
                        <localized-link v-if="navigation.prev" :to="{name: 'Article', params: { name: navigation.prev.uri }}" class="article-content__navigationItem article-content__navigationItem--right">
                            <p class="typography typography--paragraph">{{navigation.prev.title}}</p>
                            <span class="material-icons-round article-content__navigationItemImg">arrow_back_ios</span>
                        </localized-link>

                        <localized-link v-if="navigation.next" :to="{name: 'Article', params: { name: navigation.next.uri }}" class="article-content__navigationItem">
                            <span class="material-icons-round article-content__navigationItemImg">arrow_forward_ios</span>
                            <p class="typography typography--paragraph">{{navigation.next.title}}</p>
                        </localized-link>
                    </div>

                </div>
            </div>
        </div>

        <Preloader style="min-height: 35vh" v-else />

        <SectionListWrapper
            v-if="similarAritcles.length"
            :items="similarAritcles"
            :caption="$t('pages.Article.anotherArticles')"
            :theme="'light'"
            :id="99"
            :slider="isMobile"
            :has-button="false"
        />

        <Footer />
    </div>
</template>

<script>

import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';

import Footer from "@/components/Footer"
import BannerInnerPage from "@/components/BannerInnerPage"
import SectionTitle from "@/components/SectionTitle"
import SectionListWrapper from "@/components/SectionListWrapper"

export default {
    components: {
        Footer,
        BannerInnerPage,
        SectionTitle,
        SectionListWrapper,
        LPopup,
        LMarker,
        LTileLayer,
        LMap
    },

    data() {
        return {
            preloader: true,
            article: null,
            similarAritcles: [],
            sliderSettings: {
                infinite: true,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            breadLinks: [
                {
                    route: '',
                    text: '',
                },
                {
                    route: 'Article',
                    text: '~'
                },
            ],
            zoom: 8,
            mapCenter: [47.313220, -1.319482],
            navigation: {},
        }
    },

    computed: {
        othersHeaderValues() {
            return Object.entries(this.article.others).map(([key, elem]) => {
                if (elem.type == 'Dictionary' || elem.type === 'Date' || elem.type === 'Text') {
                    if (typeof elem.value == 'object' && elem.value) {
                        return `${elem.value.dictionary_name}: <b>${elem.value.page_name}</b>`
                    }
                    else {
                        return ` ${elem.name}: <b>${elem.value || '~'}</b> `
                    }
                }
            }).filter(elem => elem !== undefined)
        },

        articleContent() {
            return Object.entries(this.article.others).map(([key, elem]) => {
                if (elem.type == 'Editor') {
                    elem.order = +key
                    return elem
                }
            }).filter(elem => elem !== undefined)
        },

        articleSliders() {
            return Object.entries(this.article.others).map(([key, elem]) => {
                if (elem.type == 'Slider') {
                    elem.order = +key
                    return elem
                }
            }).filter(elem => elem !== undefined)
        },

        articleFiles() {
            return Object.entries(this.article.others).map(([key, elem]) => {
                if (elem.type == 'File') {
                    elem.order = +key
                    return elem
                }
            }).filter(elem => elem !== undefined)
        },

         articleMap() {
            return Object.entries(this.article.others).map(([key, elem]) => {
                if (elem.type == 'Tour3D Field') {
                    elem.order = +key
                    return elem
                }
            }).filter(elem => elem !== undefined)
        }
    },

    watch: {
        $route() {
            this.getArticle(this.$route.params?.name);
            this.breadLinks = [
                {
                    route: '',
                    text: '',
                },
                {
                    route: 'Article',
                    text: '~'
                },
            ],
            this.getSimilarElements(this.article?.page?.uri);
        }
    },

    async mounted() {
        await this.getArticle(this.$route.params?.name);
        await this.getSimilarElements(this.article?.page?.uri);
        document.querySelector('.leaflet-marker-icon') ? document.querySelector('.leaflet-marker-icon').click() : ''
    },

    methods: {
        getArticle(v) {
            window.scrollTo(0, 0);
            return this.api.article.view(v).then(r => {
                this.breadLinks[1].text = r.title
                this.article = r;
                this.navigation = r.navigation
            }).catch(e => {
                this.toast.error(e.response?.data?.errors)
            }).finally(() => {
                this.preloader = false
            })

        },

        getSimilarElements(chapter) {
            return this.api.article.all(chapter).then(r => {
                this.breadLinks[0].route = `/chapter/${chapter}`
                this.breadLinks[0].text = r.name

                if (r.category?.name) {
                    this.breadLinks.unshift({
                        route: `/category/${r.category.uri}`,
                        text: r.category.name,
                    })
                }

                let similarAritcles = r.posts_pagin.data;
                if (similarAritcles.length) {
                    similarAritcles = similarAritcles.filter(e => {
                        return e.id != this.article.id
                    }).slice(0, 4)
                }
                this.similarAritcles = similarAritcles
            })
        },

        fileImage(t, p) {
            switch (t) {
                case "Document":
                    if (p == "application") {
                        return require("@/assets/img/file/pdf.jpg")
                    }
                    return require("@/assets/img/file/doc.jpg")
                break;
                case "Image":
                    return require("@/assets/img/file/image.jpg")
                break;
                case "Music":
                    return require("@/assets/img/file/mp3.jpg")
                break;
                case "Video":
                    return require("@/assets/img/file/mp4.jpg")
                break;
                default:
                    return require("@/assets/img/file/empty.jpg")
                break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

    @import "@/assets/scss/article-content/index.scss";

    .article {
        &__breads {
            padding-top: 30px;
        }

        &__inner {
            padding: 70px 0 30px;
        }
    }

</style>

<style lang="scss">

    /* rewrite base library style */

    .slick-arrow.slick-prev, .slick-arrow.slick-next {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .03s;
        font-size: 28px;
        background-color: $blue;
        border-radius: 50%;
        padding: 10px;

        &:before {
            display: none;
        }
    }

    .article-content__slider {
        .slick-slide {
            > div {
                margin: 0 4px;
            }
        }

        .slick-arrow.slick-prev {
            left: -50px;
            top: 49%;
            transform: translate(0%, -50%) rotate(-90deg);
        }

        .slick-arrow.slick-next {
            right: -50px;
            left: auto;
            top: 49%;
            transform: translate(0%, -50%) rotate(-90deg);
        }
    }

    .slick-dots li {
        margin: 0;

        button {
            &:before {
                font-size: 11px;
                color: $blue
            }
        }

        &.slick-active {
            button {
                &:before {
                    color: $blue
                }
            }
        }
    }
</style>
